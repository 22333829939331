:root {
  /* Font Family */
  --default-font: Inter;
  --poppins-regular-font: PoppinsRegular;
  --poppins-medium-font: PoppinsMedium;
  --poppins-semi-bold-font: PoppinsSemiBold;
  --poppins-bold-font: PoppinsBold;

  /* Font Size */
  --font-size-2xs: 0.625rem; //10px
  --font-size-xs: 0.75rem; //12px
  --font-size-s: 0.875rem; //14px
  --font-size-m: 1rem; //16px
  --font-size-l: 1.125rem; //18px
  --font-size-xl: 1.5rem; //24px
  --font-size-2xl: 2rem; //32px
  --font-size-3xl: 2.5rem; //40px

  /* General [Color/Width/Size] */
  /*  */
  --bg-color: #01010b;
  --primary-color: #a9dfd8;
  --secondary-color: #323741;
  --shadow-color: #0000001a;
  --default-font-color: #fbfbfb;
  --form-font-color: #a1a9b8;
  --field-font-color: #64748b;
  --base-color: #21222d;
  --button-black-color: #131313;
  --button-font-color: #ffffff;

  /*  */
  --view-max-width: 1440px;
  --bo-header-height: 5.375rem;
  --bo-spacing-inline: 1.5rem;
  --bo-vertical-spacing: 1.5rem;
  --bo-sidebar-color: #0e0f19;
  --bo-sidebar-font-color: #a3a7ac;
  --header-footer-height: 102px;
  --table-footer-height: 4.25rem;
  --table-thead-height: 2.5rem;
  --table-top-container-height: 5rem;
  /*  */

  /* Icon Color */
  --icon-default: #a3a7ac;
  --icon-active: #a9dfd8;

  /* Border Color */
  --default-border-color: #323449;
  --section-border-color: #3e4553;
  --input-border-color: #868fa029;
  --input-hover-border-color: #868fa066;

  /* Status Color */
  --active-dot-color: #38a06c;
  --active-font-color: #17b26a;
  --active-tag-color: #243c48;
  --inactive-dot-color: #d7dde4;
  --inactive-font-color: #a1a9b8;
  --inactive-tag-color: #2e3539;
  --archived-dot-color: #ffc22d;
  --archived-font-color: #c6a248;
  --archived-tag-color: #483a24;

  /* Work Order and CIIS Status Color */
  --approved-font-color: #51b968;
  --approved-tag-color: #204922;
  --converted-font-color: #b9af51;
  --converted-tag-color: #494520;
  --draft-font-color: #a0c0d6;
  --draft-tag-color: #203f49;
  --new-font-color: #ceb87f;
  --new-tag-color: #484024;
  --pending-font-color: #b98951;
  --pending-tag-color: #493920;
  --rejected-font-color: #ee4444;
  --rejected-tag-color: #492020;
  --reviewed-font-color: #b98951;
  --reviewed-tag-color: #493920;
  --submitted-font-color: #96c648;
  --submitted-tag-color: #374920;

  /* Employee Status Color */
  --onboarding-font-color: #51b6b9;
  --onboarding-tag-color: #204849;

  /* Employment Status Color */
  --accepted-font-color: #51b968;
  --accepted-tag-color: #204922;
  --contract-signed-font-color: #b4ce7f;
  --contract-signed-tag-color: #444824;
  --rl-gen-font-color: #c97fce;
  --rl-gen-tag-color: #432448;
  --rl-signed-font-color: #7fceae;
  --rl-signed-tag-color: #24483f;
  --rl-emailed-font-color: #7f9cce;
  --rl-emailed-tag-color: #243248;
  --pcec-approved-font-color: #7fce7f;
  --pcec-approved-tag-color: #244824;
  --le-issued-font-color: #8f7fce;
  --le-issued-tag-color: #2e2448;
  --le-rejected-font-color: #EE4444;
  --le-rejected-tag-color: #492020;
  --hcu-emailed-font-color: #a0b9c5;
  --hcu-emailed-tag-color: #2f3438;
  --hcu-passed-font-color: #9FE7A0;
  --hcu-passed-tag-color: #2F3832;
  --hcu-failed-font-color: #EE4444;
  --hcu-failed-tag-color: #492020;
  --pp-received-font-color: #e69f68;
  --pp-received-tag-color: #513829;
  --pp-stamped-font-color: #ce887f;
  --pp-stamped-tag-color: #482824;

  /* Document Status Color */
  --generated-font-color: #96c648;
  --generated-tag-color: #374920;
  --regenerated-font-color: #b9af51;
  --regenerated-tag-color: #494520;

  /* Dashboard Variance Percentage Color */
  --increasing-font-color: #00ca92;
  --increasing-tag-color: #243c48;
  --decreasing-font-color: #ff6f70;
  --decreasing-tag-color: #492020;
  --remain-font-color: #a1a9b8;
  --remain-tag-color: #323741;

  /* Category Color */
  --accommodation-rental-color: #88a72f;
  --ciis-color: #34a59f;
  --company-color: #9259b5;
  --facilities-color: #675be7;
  --human-resource-color: #b98951;
  --office-rental-color: #2fa783;
  --others-color: #ded8e6;
  --permit-color: #d2534c;
  --telco-services-color: #2facf1;
  --user-color: #c06abc;
  --utilities-color: #1c71d4;
  --work-order-color: #a53434;
  --expatriate-document-color: #71512A;
  --visa-approver-color: #3453a5;

  /* Mobile View */
  --mobile-max-width: 992px; // refer to Bootstrap's responsive design

  --bo-layout-offset: calc(var(--bo-header-height) + var(--bo-spacing-inline));
  --table-scroll-height: calc(100vh - var(--bo-layout-offset) - var(--table-thead-height) - var(--table-footer-height) - var(--table-top-container-height));

  /* Dropdown List */
  --pill-tab-height: 3.25rem;
  --dropdown-table-top-container-height: 5.338rem;
  --dropdown-title-height: 1.813rem;
  --dropdown-table-scroll-height: calc(100vh - var(--pill-tab-height) - var(--bo-layout-offset) - var(--dropdown-title-height) - var(--table-thead-height) - var(--table-footer-height) - var(--dropdown-table-top-container-height));

  /* Work Order & CIIS List */
  // --wo-ciis-table-wrapper-height:
  --wo-ciis-overview-height: 16.5rem;
  --wo-ciis-table-scroll-height: calc(var(--table-scroll-height) - var(--wo-ciis-overview-height));
}